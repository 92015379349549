$baseurl: '/images';

@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,800,800italic');

/*
	Spectral by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@import 'libs/skel';

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1.5em )
	));

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		background: _palette(bg);

		&.is-loading {
			*, *:before, *:after {
				@include vendor('animation', 'none !important');
				@include vendor('transition', 'none !important');
			}
		}
	}

	body, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 15pt;
		font-weight: _font(weight);
		letter-spacing: _size(letter-spacing);
		line-height: 1.65em;

	input {
		font-family: _font(family);
		font-size: 15pt;
		font-weight: _font(weight);
		letter-spacing: _size(letter-spacing);
		line-height: 1.65em;
		}

		@include breakpoint(xlarge) {
			font-size: 13pt;
		}

		@include breakpoint(large) {
			font-size: 12pt;
		}

		@include breakpoint(small) {
			font-size: 11pt;
			letter-spacing: _size(letter-spacing) * 0.5;
		}
	}

	a {
		@include vendor('transition', ('color #{_duration(transitions)} ease', 'border-bottom-color #{_duration(transitions)} ease'));
		border-bottom: dotted 1px;
		color: inherit;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}
	.hlight {
		background: _palette(bg-headcol);
		letter-spacing: _size(letter-spacing-alt);
		text-transform: uppercase;
	}
	.spothead {
		color: _palette(fg-title);
		font-weight: _font(weight-extrabold);
		letter-spacing: _size(letter-spacing-alt);
		line-height: 1em;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		text-transform: uppercase;
		}
	.spotlight p {
		color: _palette(fg-title);
		}
	.spotlight li {
		color: _palette(fg-title);
		}
	.bg-head {
		background: _palette(bg-headcol);
		text-align: center;
		}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-weight: _font(weight-extrabold);
		letter-spacing: _size(letter-spacing-alt);
		line-height: 1em;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		text-transform: uppercase;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h2 {
		font-size: 1.35em;
		line-height: 1.75em;

		@include breakpoint(small) {
			font-size: 1.1em;
			line-height: 1.65em;
		}
	}

	h3 {
		font-size: 1.15em;
		line-height: 1.75em;

		@include breakpoint(small) {
			font-size: 1em;
			line-height: 1.65em;
		}
	}

	h4 {
		font-size: 1em;
		line-height: 1.5em;
	}

	h5 {
		font-size: 0.8em;
		line-height: 1.5em;
	}

	h6 {
		font-size: 0.7em;
		line-height: 1.5em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	hr {
		border: 0;
		border-bottom: solid 2px _palette(border);
		margin: (_size(element-margin) * 1.5) 0;

		&.major {
			margin: (_size(element-margin) * 2.25) 0;
		}
	}

	blockquote {
		border-left: none;
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: 0.5em 0 0.5em 2em;

	}
	/* hanging opening quote */
	blockquote:before {
	display: block;
	height: 0;
	content: "“";
	margin-left: -.95em; font: italic 400%/1 Cochin,Georgia,"Times New Roman", serif;
	color: #999;
	text-align: left;
}

	code {
		background: _palette(border-bg);
		border-radius: 3px;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		letter-spacing: 0;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			color: _palette(fg-light);
			position: relative;
			top: -0.25em;
		}

		h2 + p {
		}

		h3 + p {
			font-size: 1.1em;
		}

		h4 + p,
		h5 + p,
		h6 + p {
			font-size: 0.9em;
		}

		&.major {
			margin: 0 0 0 0;

			h2, h3, h4, h5, h6 {
				//border-bottom: solid 2px _palette(border);//
				display: inline-block;
				padding-bottom: .1em;
				position: relative;

				&:after {
					content: '';
					display: block;
					height: 1px;
				}
			}

			p {
				color: _palette(fg);
				top: 0;
			}

			@include breakpoint(small) {
				margin: 0 0 _size(element-margin) 0;
			}
		}

		@include breakpoint(medium) {
			br {
				display: none;
			}
		}
	}

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;
	}

	label {
		color: _palette(fg-bold);
		display: block;
		font-size: 0.9em;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background: _palette(border-bg);
		border-radius: 3px;
		border: none;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			box-shadow: 0 0 0 2px _palette(accent1, bg);
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			@include vendor('pointer-events', 'none');
			color: _palette(border);
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			@include icon;
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				background: _palette(border-bg);
				border-radius: 3px;
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: _palette(bg);
				color: _palette(fg-bold);
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				box-shadow: 0 0 0 2px _palette(accent1, bg);
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: 3px;
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	.formerize-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

/* Box */

	.box {
		border-radius: 3px;
		border: solid 2px _palette(border);
		margin-bottom: _size(element-margin);
		padding: 1.5em;

		> :last-child,
		> :last-child > :last-child,
		> :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		&.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}
	}

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}

		&.major {
			@include vendor('transform', 'rotate(-45deg)');
			border-radius: 3px;
			border: solid 2px _palette(border);
			display: inline-block;
			font-size: 1.35em;
			height: calc(3em + 2px);
			line-height: 3em;
			text-align: center;
			width: calc(3em + 2px);

			&:before {
				@include vendor('transform', 'rotate(45deg)');
				display: inline-block;
				font-size: 1.5em;
			}

			@include breakpoint(small) {
				font-size: 1em;
			}
		}

		&.style1 {
			color: _palette(accent2, bg);
		}

		&.style2 {
			color: _palette(accent3, bg);
		}

		&.style3 {
			color: _palette(accent4, bg);
		}
	}

/* Image */

	.image {
		border-radius: 3px;
		border: 0;
		display: inline-block;
		position: relative;

		img {
			border-radius: 3px;
			display: block;
			margin: 0 auto;
		}

		&.left {
			float: left;
			margin: 0 2em 2em 0;
			top: 0.25em;
		}

		&.right {
			float: right;
			margin: 0 0 2em 2em;
			top: 0.25em;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
		&.fit1 {
			display: block;
			/*margin: 0 0 _size(element-margin) 0;*/
			width: 100%;

			img {
				width: 50%;
			}
		}
	}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px _palette(border);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0 !important;
				}
			}

			&.major {
				padding: 1em 0;

				li {
					padding-right: 3.5em;

					@include breakpoint(small) {
						padding: 0 1em !important;
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.75) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.375) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.75) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.375) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.75);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.75)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.75);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.375);
					width: calc(100% + #{(_size(element-margin) * 0.375)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.375);
					}
				}
			}

			@include breakpoint(small) {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin: 0 auto !important;
						max-width: 30em;
						width: 100%;

						&.icon {
							&:before {
								margin-left: -1em;
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;
	}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		tbody {
			tr {
				border: solid 1px _palette(border);
				border-left: 0;
				border-right: 0;

				&:nth-child(2n + 1) {
					background-color: _palette(border-bg);
				}
			}
		}

		td {
			padding: 0.75em 0.75em;
		}

		th {
			color: _palette(fg-bold);
			font-size: 0.9em;
			font-weight: _font(weight-bold);
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		thead {
			border-bottom: solid 2px _palette(border);
		}

		tfoot {
			border-top: solid 2px _palette(border);
		}

		&.alt {
			border-collapse: separate;

			tbody {
				tr {
					td {
						border: solid 1px _palette(border);
						border-left-width: 0;
						border-top-width: 0;

						&:first-child {
							border-left-width: 1px;
						}
					}

					&:first-child {
						td {
							border-top-width: 1px;
						}
					}
				}
			}

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}
	}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', ('background-color #{_duration(transitions)} ease-in-out', 'color #{_duration(transitions)} ease-in-out'));
		background-color: transparent;
		border-radius: 3px;
		border: 0;
		box-shadow: inset 0 0 0 2px _palette(border);
		color: _palette(fg-bold);
		cursor: pointer;
		display: inline-block;
		font-size: 0.8em;
		font-weight: _font(weight-bold);
		height: 3.125em;
		letter-spacing: _size(letter-spacing-alt);
		line-height: 3.125em;
		padding: 0 2.75em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background-color: _palette(border-bg);
		}

		&:active {
			background-color: _palette(border2-bg);
		}

		&.icon {
			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.8em;
		}

		&.big {
			font-size: 1.35em;
		}

		&.special {
			background-color: _palette(accent6, bgb);
			box-shadow: none !important;
			color: _palette(accent6, fg-bold) !important;
			margin-bottom: 2em;

			&:hover {
				background-color: lighten(_palette(accent6, bg), 5) !important;
			}

			&:active {
				background-color: darken(_palette(accent6, bg), 5) !important;
			}
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}

		@include breakpoint(small) {
			height: 3.75em;
			line-height: 3.75em;
		}
	}

/* Features */

	.features {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			@include padding(4em, 4em, (0,0,0,2em));
			display: block;
			position: relative;
			text-align: left;
			width: 50%;

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(0,0,0, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				border-top: solid 2px _palette(border);
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}
.features-three {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			@include padding(1em, 1em, (0,0,0,.5em));
			display: block;
			position: relative;
			text-align: left;
			width: auto;

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(240,240,240, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				border-top: solid 2px _palette(border);
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}

.features-cta {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			padding: 0 !important;
			display: block;
			position: relative;
			text-align: left !important;
			width: 50%;

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(0,0,0, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				/*border-top: solid 2px _palette(border);*/
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}
/* Spotlight */

	.spotlight {
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		padding: 40px 20px 0 20px;

		.image {
			@include vendor('order', '1');
			border-radius: 0;
			width: 40%;

			img {
				border-radius: 0;
				width: 100%;
			}
		}

		.content {
			@include padding(2em, 2em);
			@include vendor('order', '2');
			
			width: auto;
		}

		&:nth-child(2n) {
			@include vendor('flex-direction', 'row-reverse');
		}

		@for $i from 1 through _misc(max-spotlights) {
			$j: 1 * $i;

			&:nth-child(#{$i}) {
				background-color: rgba(255,255,255, $j);
			}
		}

		@include breakpoint(large) {
			.image {
				width: 45%;
			}

			.content {
				width: 55%;
			}
		}

		@include breakpoint(medium) {
			display: block;

			br {
				display: none;
			}

			.image {
				width: 100%;
			}

			.content {
				@include padding(4em, 3em);
				max-width: none;
				
				width: 100%;
			}
		}

		@include breakpoint(small) {
			.content {
				@include padding(3em, 2em);
			}
		}
	}

/* Wrapper */

	@mixin wrapper($p) {
		background-color: _palette($p, bg);
		color: _palette($p, fg);

		// Basic

			strong, b {
				color: _palette($p, fg-bold);
			}

			h2, h3, h4, h5, h6 {
				color: _palette($p, fg-bold);
			}

			hr {
				border-color: _palette($p, border);
			}

			blockquote {
				border-color: _palette($p, border);
			}

			code {
				background: _palette($p, border-bg);
			}

		// Section/Article

			header {
				p {
					color: _palette($p, fg-light);
				}

				&.major {
					h2, h3, h4, h5, h6 {
						border-color: _palette($p, border);
					}

					p {
						color: _palette($p, fg);
					}
				}
			}

		// Form

			label {
				color: _palette($p, fg-bold);
			}

			input[type="text"],
			input[type="password"],
			input[type="email"],
			select,
			textarea {
				background: _palette($p, border-bg);
			}

			.select-wrapper {
				&:before {
					color: _palette($p, border);
				}
			}

			input[type="checkbox"],
			input[type="radio"], {
				& + label {
					color: _palette($p, fg);

					&:before {
						background: _palette($p, border-bg);
					}
				}

				&:checked + label {
					&:before {
						background: _palette($p, fg-bold);
						color: _palette($p, bg);
					}
				}
			}

			::-webkit-input-placeholder {
				color: _palette($p, fg-light) !important;
			}

			:-moz-placeholder {
				color: _palette($p, fg-light) !important;
			}

			::-moz-placeholder {
				color: _palette($p, fg-light) !important;
			}

			:-ms-input-placeholder {
				color: _palette($p, fg-light) !important;
			}

			.formerize-placeholder {
				color: _palette($p, fg-light) !important;
			}

		// Icon

			.icon {
				&.major {
					border-color: _palette($p, border);
				}
			}

		// List

			ul {
				&.alt {
					li {
						border-color: _palette($p, border);
					}
				}
			}

		// Table

			table {
				tbody {
					tr {
						border-color: _palette($p, border);

						&:nth-child(2n + 1) {
							background-color: _palette($p, border-bg);
						}
					}
				}

				th {
					color: _palette($p, fg-bold);
				}

				thead {
					border-color: _palette($p, border);
				}

				tfoot {
					border-color: _palette($p, border);
				}

				&.alt {
					tbody {
						tr {
							td {
								border-color: _palette($p, border);
							}
						}
					}
				}
			}

		// Button

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			button,
			.button {
				box-shadow: inset 0 0 0 2px _palette($p, border);
				color: _palette($p, fg-bold);

				&:hover {
					background-color: _palette($p, border-bg);
				}

				&:active {
					background-color: _palette($p, border2-bg);
				}
			}

		// Features

			.features {
				li {
					@include breakpoint(small) {
						border-top-color: _palette($p, border);
					}
				}
			}

	}

	.wrapper {
		@include padding(1em, 0);

		> .inner {
			width: 60em;
			margin: 0 auto;

			@include breakpoint(large) {
				width: 90%;
			}

			@include breakpoint(medium) {
				width: 100%;
			}
		}
		> .inner2 {
			width: 50%;
			margin: 0 auto;

			@include breakpoint(large) {
				width: 90%;
			}

			@include breakpoint(medium) {
				width: 90%;
			}

			@include breakpoint(small) {
				width: 100%;
			}
		}

		&.alt {
			padding: 0;
		}

		&.style1 {
			@include wrapper(accent1);
		}

		&.style2 {
			background-color: transparent;
		}

		&.style3 {
			@include wrapper(accent5);
		}

		&.style4 {
			background-color: transparent;
		}

		&.style5 {
			@include wrapper(accent7);
		}

		@include breakpoint(medium) {
			@include padding(1em, .5em);
		}

		@include breakpoint(small) {
			@include padding(1em, 2em);
		}
	}

/* Page Wrapper + Menu */

	#page-wrapper {
		@include vendor('transition', 'opacity #{_duration(menu)} ease');
		opacity: 1;
		padding-top: 3em;

		&:before {
			background: rgba(0,0,0,0);
			content: '';
			display: block;
			display: none;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: _misc(z-index-base) + 1;
		}
	}

	#menu {
		@include vendor('transform', 'translateX(20em)');
		@include vendor('transition', 'transform #{_duration(menu)} ease');
		-webkit-overflow-scrolling: touch;
		background: _palette(accent1, bg);
		color: _palette(accent1, fg-bold);
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		padding: 3em 2em;
		position: fixed;
		right: 0;
		top: 0;
		width: 20em;
		z-index: _misc(z-index-base) + 2;

		ul {
			list-style: none;
			padding: 0;

			> li {
				border-top: solid 1px _palette(accent1, border);
				/* margin: 0.5em 0 0 0;
				padding: 0.5em 0 0 0; */

				&:first-child {
					border-top: 0 !important;
					margin-top: 0 !important;
					padding-top: 0 !important;
				}

				> a {
					border: 0;
					color: inherit;
					display: block;
					font-size: 0.8em;
					letter-spacing: _size(letter-spacing-alt);
					outline: 0;
					text-decoration: none;
					text-transform: uppercase;

					@include breakpoint(small) {
						line-height: 3em;
					}
				}
			}
		}

		.close {
			background-image: url('images/close.svg');
			background-position: 4.85em 1em;
			background-repeat: no-repeat;
			border: 0;
			cursor: pointer;
			display: block;
			height: 3em;
			position: absolute;
			right: 0;
			top: 0;
			vertical-align: middle;
			width: 7em;
		}

		@include breakpoint(small) {
			padding: 3em 1.5em;
		}
	}

	body.is-menu-visible {
		#page-wrapper {
			opacity: 0.35;

			&:before {
				display: block;
			}
		}

		#menu {
			@include vendor('transform', 'translateX(0)');
		}
	}

/* Header */

	#header {
		@include vendor('transition', 'background-color #{_duration(transitions)} ease');
		background: _palette(bg);
		height: 3em;
		left: 0;
		line-height: 3em;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		h1 {
			@include vendor('transition', 'opacity #{_duration(transitions)} ease');
			height: inherit;
			left: 1.25em;
			line-height: inherit;
			position: absolute;
			top: 0;

			a {
				border: 0;
				display: block;
				height: inherit;
				line-height: inherit;

				@include breakpoint(small) {
					font-size: 0.8em;
				}
			}
		}

		nav {
			height: inherit;
			line-height: inherit;
			position: absolute;
			right: 0;
			top: 0;

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;
				white-space: nowrap;

				> li {
					display: inline-block;
					padding: 0;

					> a {
						border: 0;
						color: _palette(fg-bold);
						display: block;
						font-size: 0.8em;
						letter-spacing: _size(letter-spacing-alt);
						padding: 0 1.5em;
						text-transform: uppercase;

						&.menuToggle {
							outline: 0;
							position: relative;

							&:after {
								background-image: url('images/bars.svg');
								background-position: right center;
								background-repeat: no-repeat;
								content: '';
								display: inline-block;
								height: 3.75em;
								vertical-align: top;
								width: 2em;
							}

							@include breakpoint(small) {
								padding: 0 1.5em;

								span {
									display: none;
								}
							}
						}

						@include breakpoint(small) {
							padding: 0 0 0 1.5em;
						}
					}

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		&.alt {
			background: transparent;

			h1 {
				@include vendor('pointer-events', 'none');
				opacity: 0;
			}
		}
	}

/* Banner */

	#banner {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'column');
		@include vendor('justify-content', 'center');
		cursor: default;
		height: 50%;
		min-height: 35em;
		overflow: hidden;
		position: relative;
		text-align: center;
		padding-bottom: 20px;

		h2 {
			@include vendor('transform', 'scale(1)');
			@include vendor('transition', ('transform 0.5s ease', 'opacity 0.5s ease'));
			display: inline-block;
			font-size: 1.75em;
			opacity: 1;
			padding: 0.35em 1em;
			position: relative;
			z-index: 1;

			&:before, &:after {
				@include vendor('transition', 'width 0.85s ease');
				@include vendor('transition-delay', '0.25s');
				background: _palette(fg-bold);
				content: '';
				display: block;
				height: 2px;
				position: absolute;
				width: 100%;
			}

			&:before {
				top: 0;
				left: 0;
			}

			&:after {
				bottom: 0;
				right: 0;
			}
		}

		p {
			letter-spacing: _size(letter-spacing-alt);
			text-transform: uppercase;

			a {
				color: inherit;
			}
		}
		.more {
			@include vendor('transition', ('transform 0.75s ease', 'opacity 0.75s ease'));
			@include vendor('transition-delay', '3.5s');
			@include vendor('transform', 'translateY(0)');
			border: none;
			bottom: 0;
			color: inherit;
			font-size: 0.8em;
			height: 8.5em;
			left: 50%;
			letter-spacing: _size(letter-spacing-alt);
			margin-left: -10.5em;
			opacity: 1;
			outline: 0;
			padding-left: _size(letter-spacing-alt);
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			/*width: 16em;*/
			z-index: 1;

			&:after {
				background-image: url('images/arrow.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 4em;
				content: '';
				display: block;
				height: 1.5em;
				left: 50%;
				margin: 0 0 0 -0.75em;
				position: absolute;
				width: 1.5em;
			}
		}

		&:after {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity #{_duration(fadein)} ease-in-out');
			@include vendor('transition-delay', '1.25s');
			content: '';
			background: _palette(bg);
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}

		@include breakpoint(small) {
			@include padding(7em, 3em);
			height: auto;
			min-height: 0;

			h2 {
				font-size: 1.25em;
			}

			br {
				display: none;
			}

			.more {
				display: none;
			}
		}
	}

	body.is-loading {
		#banner {
			h2 {
				@include vendor('transform', 'scale(0.95)');
				opacity: 0;

				&:before, &:after {
					width: 0;
				}
			}

			.more {
				@include vendor('transform', 'translateY(8.5em)');
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}
	}

/* CTA */

	#cta {
		.inner {
			@include vendor('display', 'flex');
			/*max-width: 45em;*/

			header {
				@include vendor('order', '1');
				padding-right: 3em;
				padding-left: 3em;
				/*width: 70%;*/

				p {
					color: inherit;
				}
			}

			.actions {
				@include vendor('order', '2');
				width: auto; /* 30% */
			}

			@include breakpoint(medium) {
				display: block;
				text-align: center;

				header {
					padding-right: 0;
					width: 100%;
				}

				.actions {
					margin-left: auto;
					margin-right: auto;
					max-width: 20em;
					width: 100%;
				}
			}

			@include breakpoint(small) {
				.actions {
					max-width: none;
				}
			}
		}
	}

/* Main */

	#main {
		> header {
			background-image: url(/images/journey.jpg);
			background-attachment: fixed;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			text-align: center;

			h2 {
				font-size: 1.75em;
				margin: 0 0 (_size(element-margin) * 0.25) 0;
			}

			p {
				color: inherit;
				letter-spacing: _size(letter-spacing-alt);
				text-transform: uppercase;
				top: 0;

				a {
					color: inherit;
				}
			}

			@include breakpoint(xlarge) {
				@include padding(10em, 0);
			}

			@include breakpoint(large) {
				@include padding(8em, 3em);
			}

			@include breakpoint(medium) {
				@include padding(10em, 3em);
			}

			@include breakpoint(small) {
				@include padding(5em, 3em);

				h2 {
					font-size: 1.25em;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
				}
			}
		}
	}

	body.is-mobile {
		#main {
			> header {
				background-attachment: scroll;
			}
		}
	}

/* Footer */

	#footer {
		@include padding(6em, 0);
		background-color: darken(_palette(bg), 8);
		text-align: center;

		.icons {
			font-size: 1.25em;

			a {
				color: _palette(fg-light);

				&:hover {
					color: _palette(fg);
				}
			}
		}

		.copyright {
			color: _palette(fg-light);
			font-size: 0.8em;
			letter-spacing: _size(letter-spacing-alt);
			list-style: none;
			padding: 0;
			text-transform: uppercase;

			li {
				border-left: solid 1px _palette(fg-light);
				display: inline-block;
				line-height: 1em;
				margin-left: 1em;
				padding-left: 1em;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}

				a {
					color: inherit;

					&:hover {
						color: _palette(fg);
					}
				}

				@include breakpoint(xsmall) {
					border: 0;
					display: block;
					line-height: 1.65em;
					margin: 0;
					padding: 0.5em 0;
				}
			}
		}

		@include breakpoint(medium) {
			@include padding(4em, 3em);
		}

		@include breakpoint(small) {
			@include padding(3em, 2em);
		}
	}

/* Landing */

	body.landing {
		#page-wrapper {
			background-image: url(/images/journey.jpg);
			background-attachment: fixed;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding-top: 0;
		}

		#footer {
			/*background-color: darken(transparentize(_palette(bg), 0.1), 8);*/
			background-color: transparent !important;
		}
	}

	body.is-mobile {
		&.landing {
			#page-wrapper {
				background: none;
			}

			#banner,
			.wrapper.style4 {
				background-image: url(/images/journey.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			#footer {
				background-color: darken(_palette(bg), 8);
			}
		}
	}

/* Custom */

h3.quote-head {
    text-align: center;
    font-style: italic;
}
ul li {
    margin: 0 0 0.5em 0;
}
li.active {
    font-weight: 900;
}
.sp {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-style: italic;
    font-weight: 600;
}
.sp h3 {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-style: italic;
    font-weight: 600;
}
p.tab {
    margin: 0 80px;
    position: relative;
}
/* Spotlight Custom*/

	.spotlight2 {
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');

		.image {
			@include vendor('order', '1');
			border-radius: 0;
			width: 100%;

			img {
				border-radius: 0;
				width: 100%;
			}
		}

		.content {
			@include padding(2em, 4em);
			@include vendor('order', '2');
			max-width: 48em;
			width: 60%;
		}

		&:nth-child(2n) {
			@include vendor('flex-direction', 'row-reverse');
		}

		@for $i from 1 through _misc(max-spotlights) {
			$j: 1 * $i;

			&:nth-child(#{$i}) {
				background-color: rgba(255,255,255, $j);
			}
		}

		@include breakpoint(large) {
			.image {
				width: 100%;
			}

			.content {
				width: 55%;
			}
		}

		@include breakpoint(medium) {
			display: block;

			br {
				display: none;
			}

			.image {
				width: 100%;
			}

			.content {
				@include padding(4em, 3em);
				max-width: none;
				text-align: center;
				width: 100%;
			}
		}

		@include breakpoint(small) {
			.content {
				@include padding(3em, 2em);
			}
		}
	}
/*Image alignment*/
.image-c {
	max-width: 100%; 
	display:block; 
	height: auto;
}

/*Video responsive*/

.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
/*images*/
img.image-cen {
    margin: 0 auto;
    display: block;
}
/* Check for corrections */

@media screen and (min-width: 1680px) {
   .spotlight .image {
      width: 41%; }
   .spotlight .image-sp img {
      width: auto;
      padding-top: 10px; }
   .image-sp img {
      width: auto; }
   .spotlight .image-sp-c img {
      width: auto; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: auto; }
   .spotlight .content-vid {
      width: 100%;display: block;margin: 0 auto; }
      .spotlight .content-sp p {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: 5px; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }
@media screen and (min-width: 1200px) {
   .spotlight .image {
      width: 45%; }
      .spotlight .image-sp img {
      width: auto;
      padding-top: 10px; }
      .image-sp img {
      width: auto; }
      .spotlight .image-sp-c img {
      width: auto; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: auto; }
   .spotlight .content-vid {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: 5px; }
   .spotlight .content-sp p {
      width: 90%;display: block;margin: 0 auto; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }
@media screen and (min-width: 992px) {
   .spotlight .image {
      width: 45%; }
      .spotlight .image-sp img {
      width: auto; }
      .image-sp img {
      width: auto; }
      .spotlight .image-sp-c img {
      width: auto;
      padding-top: 10px; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: auto; }
   .spotlight .content-vid {
      width: 80%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: 5px; }
   .spotlight .content-sp p {
      width: 90%;display: block;margin: 0 auto; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }
@media screen and (min-width: 768px) {
   .spotlight .image {
      width: 45%; }
      .spotlight .image-sp img {
      width: auto;
      padding-top: 10px; }
      .image-sp img {
      width: 100%; }
      .spotlight .image-sp-c img {
      width: 80%; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: 80%; }
   .spotlight .content-vid {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: 5px; }
    .spotlight .content-sp p {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }
@media screen and (min-width: 600px) {
   .spotlight .image {
      width: 45%; }
      .spotlight .image-sp img {
      width: 100%;
      padding-top: 10px; }
     .image-sp img {
      width: 100%; }
      .spotlight .image-sp-c img {
      width: 80%; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: 80%; }
   .spotlight .content-vid {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: auto; }
   .spotlight .content-sp p {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }

@media screen and (max-width: 600px) {
   .spotlight .image {
      width: 45%; }
      .spotlight .image-sp img {
      width: 100%;
      padding-top: 10px; }
     .image-sp img {
      width: 100%; }
      .spotlight .image-sp-c img {
      width: 80%; }
   .image-sp-c {
    background: #fff;
    padding: 20px 0 0 0;}
   .image-sp-c img {
      width: 80%; }
   .spotlight .content-vid {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content-sp {
      width: auto;display: block;margin: 0 auto; padding: auto; }
   .spotlight .content-sp p {
      width: 100%;display: block;margin: 0 auto; }
   .spotlight .content {
      width: auto;display: block;margin: 0 auto; } }
/* Custom */
h2.ticket {
    background: #b0e6ad;
}
h3.ticket-s {
    background: #b0e6ad;
}
h4.ticket-xs {
    background: #b0e6ad;
}
iframe {
  width: auto;
}
p.left {
    text-align: left;
    line-break: auto;
}
blockquote.sp {
	font-size: .9em;
}
ul.prog-feature li {
    padding: 0;
    list-style: disc;
}
.inner-s {
    margin: 0 auto;
    width: 90%;
}
li.sp {
    padding: 0;
    list-style: none;
    text-align: center;
}
blockquote.sp1 {
    font-size: 1.3em;
    text-align: left;
}
h2.sp {
    border-bottom: none !important;
    padding-bottom: 0px !important;
}

h4.sp {
    font-size: .9em;
    border-bottom: 5px;
    text-transform: none !important;
    padding-top: 0px !important;
}
.inner-sp {
    width: 100%;
    padding: 0 20px 0 20px;
    border-bottom: 1em solid;
}
p.sp-head {
    width: 80%;
    margin: 0 auto;
    font-style: normal;
    text-transform: none !important;
    font-style: italic;
    text-align: justify;
    line-height: 2em;
    justify-content: center;
    padding-bottom: 1em;
}
#banner p {
    text-transform: uppercase;
    font-size: .9em;
    text-align: center;
}
p.sp-head {
    width: 80%;
    margin: 0 auto;
    font-style: normal;
    text-transform: none !important;
    font-style: italic;
    text-align: left !important;
    line-height: 2em;
    padding-bottom: 1em;
}
h2.sp-home {
	font-size: 1.5em;
    padding-top: 20px;
    //border-top: 2px solid;//
    margin-top: .5em;
}
/* members login */
.MemberSpaceWidgetInternal__BrandedInterface_footer {
    display: none !important;
    justify-content: center;
    margin-top: 20px;
}
/* Banner Heads */
h3.intro {
    font-size: 1.8em !important;
}
h4.sp3{
	padding: 5px;
	color: #c83092 !important;
	border: none !important;
}
/* Table */
thead.tmh-th {
    background-color: #c871a3;
    font-size: 1.2em !important;
}
td.tmh-prog {
    text-align: left;
}
.wrapper.style3 table th {
    color: #fff;
    padding: 15px;
}

.events-date {
    text-align: center;
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: rgba(25, 47, 89, 0.9);
    color: #fff;
    padding: 12px 20px 8px 20px;
    text-transform: uppercase
}

.event-time li {
    display: inline-block;
    margin-right: 20px
}

.event-time li:last-child {
    margin-right: 0
}

.event-time li i {
    color: #c871a3
}

.event-block {
    box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48)
}

.event-block ul li i {
    color: #59c17a
}

@media screen and (max-width: 1199px) {
    .event-date {
        padding: 10px 18px 6px 18px
    }
}

@media screen and (max-width: 575px) {
    .event-date {
        padding: 8px 15px 4px 15px
    }
    .events-date {
        padding: 10px 15px 6px 15px
    }
}

.position-relative {
    position: relative !important;
}

.margin-40px-bottom {
    margin-bottom: 40px;
}
.padding-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
}

.margin-15px-bottom {
    margin-bottom: 15px;
}
.font-weight-500 {
    font-weight: 500;
}
.font-size22 {
    font-size: 22px;
}
.text-theme-color {
    color: #192f59;
}
.margin-10px-bottom {
    margin-bottom: 10px;
}
.margin-10px-right {
    margin-right: 10px;
}
/* Newsletter */
input.nl-tmh {
    border: 1px solid;
    background-color: #fff5fb !important;
    margin-bottom: 15px;
}
/* About Blubr */
h2.about-blurb {
    border: none !important;
    color: #192f59 !important;
}
h5.about-brb {
    font-size: 1.1em;
    color: #192f59 !important;
    border: none !important;
}
h3.nbdr {
    border: none !important;
}
.tmh-b {
	text-align: left;
}
h5.bform {
    color: green !important;
    font-weight: bold;
    font-family: 'Lato' !important;
}
form.tmh-b {
    border: 1px solid #ccc;
    padding: 10px;
}
h4.sp3 {
    font-size: 1.4em;
    padding: 0;
}
blockquote.bleft {
	text-align: left;
}
h2.head-cta {
	font-family: 'Cinzel', sans-serif; 
    font-size: 1.6em;
    line-height: 1.75em;
    
}
h2.head-courses {
	font-size: 1.4em;
    color: #c871a3 !important;
}

h4.coures-sp {
    font-size: 1.2em;
    padding-bottom: 10px;
}
.features-courses {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding-bottom: 10px;
		width: 90%;
		margin: 0 auto;

		li {
			padding: 5px !important;
			display: block;
			position: relative;
			text-align: left;
			width: 50%;
			background: #f7f1f2 !important;

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: #f7f1f2 !important;
					text-align: left;
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				/*border-top: solid 2px _palette(border);*/
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}
blockquote.sessions {
	font-family: inherit;
	font-size: 1.1em;
	color:#3d5072;
	text-align: left;
	}
blockquote.sessions:before {
	display: none;
	height: 0;
	content: "“";
	margin-left: -.95em; 
	color: #999;
	text-align: left;
	}
h4.pay {
    text-align: left !important;
    font-family: 'Lato' !important;
    color: #c96391 !important;
}
section#cta-nl {
    text-align: center;
}
.three-p {
	font-size: 1.2em !important;
}
/* accordian */
ul.jekyllcodex_accordion {
position: relative; 
/*margin: 1.4rem 0!important; 
border-bottom: 1px solid rgba(0,0,0,0.25);*/
padding-bottom: 0;
}
ul.jekyllcodex_accordion li {
/* border-top: 1px solid rgba(0,0,0,0.25); */
list-style: none; 
margin-left: 0;
padding: 0px;
}
ul.jekyllcodex_accordion li input {
display: none;
}
ul.jekyllcodex_accordion li label {
display: block;
cursor: pointer; 
padding: 0.75rem 2.4rem 0.75rem 0; 
margin: 0;
}
ul.jekyllcodex_accordion li div {
display: none; 
padding-bottom: 1.2rem;
}
ul.jekyllcodex_accordion li input:checked + label {
font-weight: bold;
padding: 10px 0px 10px 0px;
}
ul.jekyllcodex_accordion li input:checked + label + div {
display: block;
}
ul.jekyllcodex_accordion li label::before {
content: "+"; 
font-weight: normal; 
line-height: 1.1rem; 
padding: 0; 
position: relative !important; 
right: 0.5rem; 
transition: all 0.15s ease-in-out;
background: none !important;
color: #444 !important;
}
ul.jekyllcodex_accordion li input:checked + label::before {
transform: rotate(0);
}
/* Staff */
.staff{
    display:-webkit-box;
    display:-webkit-flex;
    display:-moz-flex;
    display:-ms-flexbox;
    display:flex
}
.staff{
    padding:0;
    margin:0;
    list-style:none;
    -webkit-flex-flow:wrap;
    -moz-flex-flow:wrap;
    -ms-flex-flow:wrap;
    flex-flow:wrap;
    text-align:center;
}
.staff li{
    padding:30px 20px;
    box-sizing:border-box;
    width:100%
}
@media (max-width: 767px){
    .staff li{
        -webkit-box-flex:1;
        -webkit-flex:1 1 25%;
        -moz-box-flex:1;
        -moz-flex:1 1 25%;
        -ms-flex:1 1 25%;
        flex:1 1 25%;
    }
}
@media (max-width: 768px) and (max-width: 1023px) {
    .staff li{
        -webkit-box-flex:1;
        -webkit-flex:1 1 25%;
        -moz-box-flex:1;
        -moz-flex:1 1 25%;
        -ms-flex:1 1 25%;
        flex:1 1 25%;
    }
}
@media (max-width: 1025px) {
    .staff li{
        -webkit-box-flex:1;
        -webkit-flex:1 1 25%;
        -moz-box-flex:1;
        -moz-flex:1 1 25%;
        -ms-flex:1 1 25%;
        flex:1 1 25%;
    }
}
.staff .name{
    margin-top:20px;
}
.name p {
    margin: 0;
}
.staff .position{
    color:#666;
    font-size:.8em;
}
.square-image{
    width:200px;
    height:200px;
    border-radius:200px;
    background-size:cover;
    background-repeat:no-repeat;
    margin:0 auto;
}
/* Calendar font */
h3.cal {
    font-size: .9em;
    color: #800080 !important;
}
h3.cal2 {
    font-size: 1em;
    color: #800080 !important;
    text-align: left;
}
/* misc */
li.icon.fa-group {
    padding-bottom: 5px !important;
}
/* Responsive iFrame */

iframe {
    width: 100% !important;
}

.responsive-iframe-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px;
height: 0;
overflow: hidden;
}

.responsive-iframe-container iframe,
.vresponsive-iframe-container object,
.vresponsive-iframe-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

/* Features */

	.features {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			@include padding(.5em, 1em, (0,0,0,2em));
			display: block;
			position: relative;
			text-align: left !important;
			width: 50%;
		

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(0,0,0, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				border-top: solid 2px _palette(border);
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}
.features-three {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			@include padding(1em, 1em, (0,0,0,1em));
			display: block;
			position: relative;
			text-align: left;
			/*width: auto;*/

			@for $i from 1 through _misc(max-features) {
				$j: 0.01 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(240,240,240, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				border-top: solid 2px _palette(border);
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}
h5.sp-blk {
    color: #c871a3 !important;
    font-style: italic;
    border: none !important;
}
/* timeline */

.hori-timeline {
	padding-top: 2em;
}
/*.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}*/
.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}
.hori-timeline .events .event-list:before {
    content: "";
    position: inherit;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
    margin-left: -8px;
}
.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: auto;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}
@media (max-width: 600px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (min-width: 768px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (min-width: 992px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (min-width: 1200px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (min-width: 1680px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
.bg-soft-primary {
    background-color: rgba(64,144,203,.3)!important;
}
.bg-soft-success {
    background-color: rgba(71,189,154,.3)!important;
}
.bg-soft-danger {
    background-color: rgba(231,76,94,.3)!important;
}
.bg-soft-warning {
    background-color: rgba(179,112,249,.3)!important;
}
.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
    box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
}

/* @font-face {
    font-family: Cinzel;
    src: url(/fonts/Cinzel-Regular.woff);
}

body{
    font-family: Cinzel;
}
p{
	font-family: FaunaOne !important;
} */